import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import loadable from "@loadable/component";
const Parser = loadable(() => import("../parser.js"));
const NavMenu = loadable(() => import("./header/menu/nav-menu.js"));

const Footer = () => {
  const props = useStaticQuery(query);
  const footer = props.wpTemplate?.content;
  const [elevatorState, setElevatorState] = useState(false);
  if (typeof window !== "undefined") {
    useEffect(() => {
      const scrollWatcher = () => {
        if (window.scrollY > window.innerHeight && !elevatorState) {
          setElevatorState(true);
        }
        if (window.scrollY < window.innerHeight && elevatorState) {
          setElevatorState(false);
        }
      };
      window.addEventListener("scroll", scrollWatcher);
      return () => { window.removeEventListener("scrollr", scrollWatcher); };
    }, [elevatorState, setElevatorState, window]);
  }
  const nav = (attrs) => (<NavMenu className={ attrs.className } nav={ props.allWpMenu } id="footerMenu" label="Menu de navigation de pied de page" visible={ true }/>);
  return (
    <>
      <a href="#" title="Revenir en haut" id="backtotop" className={ elevatorState ? "expanded":"collapsed" }><span className="sr-only">Utilisez ce bouton pour revenir en haut de la page.</span></a>
      <Parser content={ footer } child={ nav }/>
    </>);
};

export const query = graphql`
  query {
    allWpMenu(filter: {locations: {in: FOOTER_NAVIGATION}}) {
      edges {
        node {
          menuItems {
            nodes {
              id
              path
              label
              parentId
              databaseId
            }
          }
        }
      }
    }
    wpTemplate(template_reference: {referenceTemplate: {eq: "footer"}}) {
      content
    }
  }
`;
export default Footer;
